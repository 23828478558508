<template>
  <div class="xinsheng-warp system-warp">
    <FilterForm
      :formData="filterData"
      :filters="filters"
      @changeDept="changeDept"
      @submit="handleSubmit"
      @reset="handleReset"
    />

    <LoginLogTable
      v-if="tableData"
      :tableColumn="tableColumn"
      :tableData="tableData"
    />
    <LoginLogPagination
      :total="total"
      @currentChange="currentChange"
      @sizeChange="sizeChange"
    />

  </div>

</template>

<script>
import FilterForm from "@/components/filter";
import { computed, onMounted, reactive } from "vue";
import { tableColumn } from "./index.js";
import LoginLogTable from "@/components/table";
import LoginLogPagination from "@/components/pagination";
import { useStore } from "vuex";
import { getUserByDept } from "@/api/method/common";
import moment from "moment";
import { reset } from "@/until";

export default {
  name: "LoginLog",
  components: {
    FilterForm,
    LoginLogTable,
    LoginLogPagination,
  },
  setup() {
    const { dispatch, state } = useStore();
    const filterData = reactive({
      deptId: null,
      userId: null,
      date: null,
    });
    const filters = reactive([
      [
        {
          label: "选择部门",
          type: "select",
          attr: "deptId",
          placeholder: "请选择",
          column: 6,
          option: [],
          labelWidth: "80px",
        },
        {
          label: "选择员工",
          type: "select",
          attr: "userId",
          placeholder: "请选择",
          column: 6,
          option: [],
          labelWidth: "100px",
        },
        {
          label: "操作时间",
          type: "dateRange",
          attr: "date",
          column: 8,
          labelWidth: "100px",
        },
        {
          type: "button",
          column: 4,
        },
      ],
    ]);

    const pagination = reactive({
      page: 1,
      size: 10,
    });

    const tableData = computed(
      () => state.loginLogs && state.loginLogs.records
    );
    const total = computed(() => state.loginLogs && state.loginLogs.total);

    const getList = () => {
      const { date, ...param } = filterData;

      const startTime = date && moment(date[0]).format("YYYY-MM-DD");
      const endTime = date && moment(date[1]).format("YYYY-MM-DD");
      dispatch("loginLog", { ...pagination, startTime, endTime, ...param });
    };

    const currentChange = (page) => {
      pagination.page = page;
      getList();
    };
    const sizeChange = (size) => {
      pagination.size = size;
      getList();
    };
    const getDepartList = async () => {
      await dispatch("departmentList");
      filters[0][0].option = state.departmentList.map((item) => ({
        label: item.fullName,
        val: item.id,
      }));
    };

    const changeDept = async (id) => {
      const res = await getUserByDept(id);
      filters[0][1].option = res.data.map((item) => ({
        label: item.nickname,
        val: item.id,
      }));
    };

    onMounted(() => {
      getList();
      getDepartList();
    });

    const handleSubmit = () => getList();
    const handleReset = () => {
      reset(filterData);
      getList();
    };

    return {
      filters,
      filterData,
      tableColumn,
      tableData,
      total,
      pagination,
      currentChange,
      sizeChange,
      changeDept,
      handleSubmit,
      handleReset,
    };
  },
};
</script>

<style lang="scss" scoped>
.user-btn {
  padding-bottom: 40px;
}
.container {
  display: flex;
  min-height: 100%;
  .left {
    width: 250px;
    background: #f5f6f9;
    margin-right: 24px;
    padding: 20px;
  }
  .right {
    flex: 1;
    background-color: #fff;
    overflow: hidden;
  }
}
</style>

<style lang="scss">
.company-block {
  margin-bottom: 20px;
}

.company-block .el-input__inner {
  background: white !important;
}
.depart-tree {
  background: #f5f6f9;
  .el-tree-node__content {
    background: #f5f6f9;
  }
}
</style>