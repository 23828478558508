export const tableColumn = [
	{
		label: '员工姓名',
		prop: 'username',
	},
	{
		label: '行为',
		prop: 'os',
	},
	{
		label: '登录IP地址',
		prop: 'ipAddress',
	},
	{
		label: '登录位置',
		prop: 'loginLocation',
	},
	{
		label: '操作时间',
		prop: 'loginTime',
		valueFormat: 'YYYY-MM-DD',
	},
]
